import React, { useState, useEffect } from "react";
import "./faq.css";

// TypeScript types
interface FAQQuestion {
  question: string;
  answer: string;
}

interface FAQCategory {
  category: string;
  questions: FAQQuestion[];
}

const FAQ: React.FC = () => {
  const [faqData, setFaqData] = useState<FAQCategory[]>([]);
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const fetchFAQ = async () => {
      try {
        const res = await fetch("/faq.json");
        const data = await res.json();
        setFaqData(data);
      } catch (err) {
        console.error("Error loading FAQ:", err);
      }
    };
    fetchFAQ();
  }, []);

  const toggleItem = (id: string) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((category, catIdx) => (
        <div key={catIdx} className="faq-category">
          <h2>{category.category}</h2>
          <div className="accordion">
            {category.questions.map((q, qIdx) => {
              const id = `item-${catIdx}-${qIdx}`;
              const isOpen = openItems[id];
              return (
                <div key={id} className="accordion-item">
                  <button
                    className={`accordion-trigger ${isOpen ? "open" : ""}`}
                    onClick={() => toggleItem(id)}
                  >
                    {q.question}
                    <span className={`chevron ${isOpen ? "open" : ""}`}>
                      &#9662;
                    </span>
                  </button>
                  <div className={`accordion-content ${isOpen ? "show" : ""}`}>
                    {q.answer}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
