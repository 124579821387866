import React from "react";
import "./Home.css";
import HeroSection from "./sections/heroSection";
import ScoreboardSection from "./sections/scoreboardSection";
import RankingSection from "./sections/rankingSection";
import PartySection from "./sections/partySection";
import StatsSection from "./sections/statsSection";
import DownloadButton from "./components/downloadButton";
import EuropartyLogo from "./components/Logo";

const Home: React.FC = () => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Europarty app - rank Eurovision songs!",
          text: "Rank Eurovision songs and see how fans, friends and family voted",
          url: "https://europarty.app",
        });
      } catch (error) {
        console.error("Error sharing content", error);
      }
    } else {
      navigator.clipboard.writeText("https://europarty.app");
    }
  };

  return (
    <div className="page">
      <header className="header-home">
        <EuropartyLogo />
        <div style={{ display: "flex", gap: 10 }}>
          <button
            onClick={handleShare}
            style={{ border: "none", background: "none", cursor: "pointer" }}
          >
            <svg
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              height="1.5em"
              width="1.5em"
            >
              <path d="M21 5 A3 3 0 0 1 18 8 A3 3 0 0 1 15 5 A3 3 0 0 1 21 5 z" />
              <path d="M9 12 A3 3 0 0 1 6 15 A3 3 0 0 1 3 12 A3 3 0 0 1 9 12 z" />
              <path d="M21 19 A3 3 0 0 1 18 22 A3 3 0 0 1 15 19 A3 3 0 0 1 21 19 z" />
              <path d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
            </svg>
          </button>
          <DownloadButton minimal />
        </div>
      </header>
      <div className="content">
        <HeroSection />

        <ScoreboardSection />

        <RankingSection />

        <PartySection />

        <StatsSection />

        <div
          style={{
            scale: "1.2",
            justifyItems: "center",
            gap: ".5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <EuropartyLogo />
          <DownloadButton />
        </div>

        <footer>
          <div className="footer-buttons">
            <a href="/privacy">PRIVACY POLICY</a>
            <a href="/terms">TERMS & CONDITIONS</a>
            <a href="mailto:hello@europarty.app">CONTACT US</a>
          </div>
          <a
            href="https://www.instagram.com/europartyapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.5em"
                width="1.5em"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </button>
          </a>
          <span>2025 © EUROPARTY</span>
          <span>
            DISCLAIMER: Europarty is an independent app and is not affiliated
            with the Eurovision Song Contest or the European Broadcasting Union
            (EBU), both of which are trademarks of the EBU. All trademarks are
            the property of their respective owners. Voting and ranking in the
            app do not affect the official contest results.
          </span>
        </footer>
      </div>
    </div>
  );
};

export default Home;
