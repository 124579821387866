import React from "react";
import "./rankingSection.css";
import CountryPool from "../components/CountryPool";

const RankingSection: React.FC = () => {
  return (
    <div className="grid-container ranking">
      <div className="stage-bg">
        <div className="country-pool">
          <CountryPool />
        </div>
      </div>
      <div className="text-container">
        <div className="large">GIVE YOUR DOUZE-POINTS AND SEE EUROPE’S</div>
        <div className="regular">
          Create your Top 10 and see how fans from every country voted in full
          animation in the{" "}
          <div className="label-text">
            <svg
              width=".8rem"
              height=".8rem"
              viewBox="0 0 24 24"
              fill="none"
              stroke={"#090909"}
              strokeWidth={1.5}
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M21 12 A9 9 0 0 1 12 21 A9 9 0 0 1 3 12 A9 9 0 0 1 21 12 z" />
              <path d="M3.6 9h16.8M3.6 15h16.8M11.5 3a17 17 0 000 18M12.5 3a17 17 0 010 18" />
            </svg>
            GLOBAL VOTE
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankingSection;
